import { Link } from 'react-scroll';
import Kabeko_Logo from '../assets/img/Kabeko_Logo.png';

export default function Footer() {
  return (
    <section className='text-primary max-w-screen-2xl mx-auto ' id='kontakt'>
      <footer className=' bg-white lg:pt-10'>
        <div className='max-w-6xl h-full mx-auto xl:px-0 sm:px-10 px-8'>
          <div className='md:grid grid-cols-12 w-full h-full pt-10'>
            <div className='lg:col-span-6 col-span-3 w-full lg:pr-32 cursor-pointer'>
              <Link to='hero' spy={true} smooth={true} duration={500}>
                <img
                  src={Kabeko_Logo}
                  alt='big logo'
                  className='w-32 lg:w-44 hidden md:block'
                />
              </Link>
            </div>
            <div className='lg:col-span-3 md:col-span-3 w-full mt-8 sm:mt-0'>
              <h3 className='uppercase font-bold text-primary'>Innehåll</h3>
              <div className='font-poppins my-2 text-whitegray'>
                <Link to='hero' spy={true} smooth={true} duration={500}>
                  <p className='cursor-pointer'>Hem</p>
                </Link>
                <Link to='kabeko' spy={true} smooth={true} duration={500}>
                  <p className='cursor-pointer mt-1'>Om Kabeko Kraft</p>
                </Link>
                <Link to='vindkraft' spy={true} smooth={true} duration={500}>
                  <p className='cursor-pointer mt-1'>Svensk Elkraft</p>
                </Link>
                <Link to='norr' spy={true} smooth={true} duration={500}>
                  <p className='cursor-pointer mt-1'>Vindkraft i Norrland</p>
                </Link>
                <Link to='features' spy={true} smooth={true} duration={500}>
                  <p className='cursor-pointer mt-1'>
                    Driftsatta Vindkraftparker
                  </p>
                </Link>
              </div>
            </div>
            {/* <div className='lg:col-span-3 md:col-span-3 w-full mt-5 md:mt-0'>
              <h3 className='uppercase font-bold text-primary'>Policy</h3>
              <div className='my-2 text-whitegray font-poppins'>
                <p className=''>Terms and conditions</p>
                <p className=' mt-1'>Coodie Policy</p>
                <p className=' mt-1'>Privacy Policy</p>
              </div>
            </div> */}
            <div className='lg:col-span-3 md:col-span-3 w-full mt-5 md:mt-0'>
              <h3 className='uppercase font-bold text-primary'>Kontakt</h3>
              <div className='my-2 text-whitegray font-poppins'>
                <div className='flex items-center'>
                  <div className='mr-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='20  '
                      width='20'
                      viewBox='0 0 32 32'
                    >
                      <title>contactless card</title>
                      <g fill='#212121' class='nc-icon-wrapper'>
                        <path
                          d='M30,4H2A2,2,0,0,0,0,6V26a2,2,0,0,0,2,2H30a2,2,0,0,0,2-2V6A2,2,0,0,0,30,4ZM5.5,9h6a.5.5,0,0,1,.5.5v4a.5.5,0,0,1-.5.5h-6a.5.5,0,0,1-.5-.5v-4A.5.5,0,0,1,5.5,9Zm11,14H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h11a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,16.5,23ZM18,13a1,1,0,1,1,1-1A1,1,0,0,1,18,13Zm2.455-2.364,1.769-.983a3.936,3.936,0,0,1,0,4.694l-1.769-.983A1.979,1.979,0,0,0,20.455,10.636ZM26.5,23h-5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,26.5,23Zm-.764-6.7-1.747-.971a5.98,5.98,0,0,0,0-6.654L25.736,7.7A7.939,7.939,0,0,1,25.736,16.3Z'
                          fill='#9fb469'
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <p className='whitespace-nowrap'>BOX 15025, 750 15 Uppsala</p>
                </div>
                <div className='flex items-center mt-1'>
                  <div className='mr-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='20'
                      width='20'
                      viewBox='0 0 48 48'
                    >
                      <title>letter</title>
                      <g fill='#9fb469' class='nc-icon-wrapper'>
                        <path
                          data-color='color-2'
                          d='M42,5H6a5,5,0,0,0-5,5v4a1,1,0,0,0,.521.878l22,12a1,1,0,0,0,.958,0l22-12A1,1,0,0,0,47,14V10A5,5,0,0,0,42,5Z'
                        ></path>
                        <path
                          d='M25.437,28.634a3,3,0,0,1-2.87,0L1.023,16.885,1,16.9V38a5,5,0,0,0,5,5H42a5,5,0,0,0,5-5V16.9l-.023-.014Z'
                          fill='#9fb469'
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <p className='whitespace-nowrap'>Kontakt@kabeko.com</p>
                </div>
                {/* <div className='flex items-center mt-1'>
                  <div className='mr-2'>
                    <svg
                      width='20'
                      height='20'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                    >
                      <title>contact_phone</title>
                      <g fill='none' class='nc-icon-wrapper'>
                        <path
                          d='M22 3H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm3.85-4h1.64L21 16l-1.99 1.99A7.512 7.512 0 0 1 16.28 14c-.18-.64-.28-1.31-.28-2s.1-1.36.28-2a7.474 7.474 0 0 1 2.73-3.99L21 8l-1.51 2h-1.64c-.22.63-.35 1.3-.35 2s.13 1.37.35 2z'
                          fill='#9fb469'
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <p className='whitespace-nowrap'>+46730546532</p>
                </div> */}
              </div>
            </div>
            <div className='flex items-center mt-14'>
              <img
                src={Kabeko_Logo}
                alt='big logo'
                className='w-32 lg:w-auto md:hidden'
              />
            </div>
          </div>
          <div className='w-full h-0.5 bg-gray-200 mx-auto rounded-full lg:mt-28 md:mt-20 mt-16' />
          <p className='text-gray-500 text-base text-center my-6 font-montserrat'>
            Copyright © 2021 Kabeko Kraft | Naesmark.com Web Design
          </p>
        </div>
      </footer>
    </section>
  );
}
