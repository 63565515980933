import { Link } from 'react-scroll';
import HeroBGDesk from '../assets/img/HeroImageDesktop.jpg';
import HeroBGMobile from '../assets/img/HeroImageMobile.jpg';
import useWindowDimensions from '../components/getWindowDimensions';

export default function Hero() {
  const dimension = useWindowDimensions();
  return (
    <section className='relative h-screen lg:pt-80 pt-64' id='hero'>
      <div className='absolute bottom-0 left-0 w-full h-full z-0 object-scale-down'>
        {dimension.width > 640 ? (
          <img src={HeroBGDesk} className='w-full h-full' alt='' />
        ) : (
          <img src={HeroBGMobile} className='w-full h-full' alt='' />
        )}
      </div>
      <div className='relative z-40'>
        <div className='text-center mb-5 font-bold uppercase text-primary lg:text-5xl md:text-4xl text-2xl font-montserrat tracking-wide'>
          Vindkraftsutveckling
          <br /> i norrland sedan 2008
        </div>
        <div className='lg:mt-10 mt-6 text-center w-full'>
          <Link
            className='whitespace-nowrap block lg:px-4 px-3 py-2 text-center transition-color duration-300 outline-none hover:text-primarylight text-primary cursor-pointer font-montserrat font-medium text-base'
            to='kabeko'
            spy={true}
            smooth={true}
            duration={500}
          >
            <button className='font-montserrat rounded-full bg-secondary lg:py-4 lg:px-8 px-5 py-2 hover:bg-secondarylight uppercase font-bold md:text-lg text-base text-white'>
              Läs Mer om oss
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
