import Illu1 from '../assets/img/kabeko_illu_4.png';

export default function Kabeko() {
  return (
    <section className='relative md:py-36 py-20 bg-gray-50' id='kabeko'>
      <div className='relative max-w-xl p-6 mx-auto lg:px-8 lg:max-w-7xl'>
        <div className='grid grid-cols-4 gap-20 md:gap-16 items-center'>
          <div className='lg:col-span-3 col-span-4'>
            <h1 className='text-primary'>Om Kabeko</h1>
            <p className='mt-2 text-whitegray'>
              Kabeko Kraft grundades 2008. Vi arbetar med utveckling av
              vindkraftsprojekt i mellersta Norrland. Vi identifierar
              markområden med förutsättningar för vindkraft och upprättar
              ansökningshandlingar. Vår affärsidé är att driva projekten fram
              till byggstart.
            </p>

            <p className='mt-3 text-whitegray'>
              Vår vision är att bidra till en långsiktig och förnybar
              elkraftproduktion i det nordiska kraftsystemet och samtidigt bidra
              till utveckling i bygden kring våra projekten. Vår målsättning är
              att våra vindkraftsparker ska bli miljömässigt hållbara och få
              starkt lokalt stöd av bygden.
            </p>
          </div>
          <div className='lg:col-span-1 col-span-4'>
            <img
              src={Illu1}
              alt='Illustration 1'
              className='lg:w-auto w-72 mx-auto text-center'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
