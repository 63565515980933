import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';

const NotFound = () => {
  return (
    <section className='flex items-center h-screen p-16 dark:bg-gray-900 dark:text-gray-100 font-montserrat text-primary'>
      <div className='container flex flex-col items-center justify-center px-5 mx-auto my-8'>
        <div className='max-w-xl text-center'>
          <h2 className='mb-8 font-extrabold lg:text-9xl text-7xl dark:text-gray-600'>
            <span className='sr-only'>Error</span>404
          </h2>
          <p className='text-2xl font-semibold md:text-3xl'>
            Tyvärr kunde vi inte hitta denna sida
          </p>
          <p className='mt-4 mb-8 dark:text-gray-400'>
            Men oroa dig inte, det finns mycket annat på vår hemsida!
          </p>
          <div className='flex items-center mx-auto justify-center'>
            <a
              rel='noopener noreferrer'
              href='/'
              className='py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900'
            >
              Tillbaks till hemsidan
            </a>
            <AiOutlineArrowRight className='text-xl ml-3' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
