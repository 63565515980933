import emailjs from 'emailjs-com';
import React, { useRef, useState } from 'react';
import { BiMailSend } from 'react-icons/bi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../components/ContactModalFunctionality';

function Contact() {
  const [formData, setFormData] = useState({});
  const ref = useRef();
  const closeModal = () => ref.current?.close();

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs
      .sendForm(
        'hanknasmarkgmail',
        'template_265lk8u',
        event.target,
        'user_r6bkEoPepEEKk7MiQd7lF'
      )
      .then(
        (result) => {
          toast.success('Success! We will get back to you!');
          closeModal();
        },
        (error) => {
          toast.error('Error: Something went wrong, try again later!');
        }
      );

    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className='relative max-w-4xl rounded-xl bg-primary rounded-large'>
        <div className='container px-5 lg:px-10 py-10 lg:py-12 mx-auto'>
          <div className='flex flex-col text-center w-full mb-5'>
            <h1 className='mt-2 text-white'>Kontakta oss</h1>
            <p className='max-w-lg text-base text-center text-darkgray mx-auto lg:mb-1 lg:mt-3 mt-2'>
              Fyll i formuläret nedan så återkommer vi!
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className='md:max-w-2xl px-6 md:px-0 mx-auto   text-white'>
              <div className='flex flex-wrap -m-2 max-w-lg mx-auto'>
                <div className='p-2 w-full'>
                  <div className='relative'>
                    <label
                      for='name'
                      className='ml-1 leading-7 text-sm lg:text-base font-semibold'
                    >
                      Namn
                    </label>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      className='lg:mt-1 w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 
                      focus:ring-theme10 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                      control-id='ControlID-80'
                      onChange={updateInput}
                      value={formData.name || ''}
                    />
                  </div>
                </div>
                <div className='p-2 w-full'>
                  <div className='relative'>
                    <label
                      for='email'
                      className='ml-1 leading-7 text-sm lg:text-base font-semibold'
                    >
                      E-post
                    </label>
                    <input
                      type='email'
                      id='email'
                      name='email'
                      className='lg:mt-1 w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 
                      focus:ring-theme10 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                      onChange={updateInput}
                      value={formData.email || ''}
                    />
                  </div>
                </div>
                <div className='p-2 w-full'>
                  <div className='relative'>
                    <label
                      for='message'
                      className='ml-1 leading-7 text-sm lg:text-base font-semibold'
                    >
                      Meddelande
                    </label>
                    <textarea
                      id='message'
                      name='message'
                      className='lg:mt-1 w-full bg-white bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 
                      focus:ring-theme10 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
                      onChange={updateInput}
                      value={formData.message || ''}
                    ></textarea>
                  </div>
                </div>
                <div className='p-2 w-full'>
                  <button className='flex mx-auto items-center text-white bg-secondary border-0 py-3 px-7 focus:outline-none hover:bg-blue-100 rounded-full text-sm lg:mt-4 uppercase font-bold transition-all'>
                    <span className=''>Skicka meddelande</span>
                    <BiMailSend className='ml-3 text-2xl' />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default Contact;
