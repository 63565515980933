import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ModalProvider } from 'react-modal-hook';
import App from './App';
import './index.css';

ReactDOM.render(
  <Suspense fallback='loading'>
    <ModalProvider>
      <App />
    </ModalProvider>
  </Suspense>,
  document.getElementById('root')
);
