import React from 'react';
import { Link } from 'react-scroll';

function NavLinks(props) {
  const menuData = [
    // {
    //   title: 'Hem',
    //   link: 'hero',
    // },
    {
      title: 'Om Kabeko',
      link: 'kabeko',
    },
    {
      title: 'Svensk Elkraft',
      link: 'vindkraft',
    },
    {
      title: 'Vindkraft i Norrland',
      link: 'norr',
    },
    {
      title: 'Driftsatta Vindkraftparker',
      link: 'features',
    },
  ];

  const closeBurgerIftoggled = () => {
    if (props.hamburgerToggled === true) props.setHamburgerToggled(false);
  };

  return (
    <div
      className={`${
        props.isScrolled ? 'text-white' : 'text-primary'
      } lg:flex md:mx-4 lg:mx-5 text-primary`}
    >
      {menuData.map((data, index) => {
        return (
          <Link
            className='whitespace-nowrap block lg:px-4 px-3 py-2 text-center transition-color duration-300 outline-none hover:text-primarylight text-primary cursor-pointer font-montserrat font-medium text-base'
            to={data.link}
            key={index}
            onClick={closeBurgerIftoggled}
            spy={true}
            smooth={true}
            duration={500}
          >
            {data.title}
          </Link>
        );
      })}
    </div>
  );
}

export default NavLinks;
