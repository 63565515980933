import React from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function Features() {
  const VindKraftPark = () => {
    const cardData = [
      {
        name: 'Rammeldalsberget',
        kommun: 'Kramfors kommun',
        text: 'Vindpark Rammeldalsberget ligger i Kramfors kommun, ca 8 km nordöst om Kramfors. 2016 driftsattes sex vindkraftverk med totalt installerad effekt 15,9 MW. Vindkraftverkens totalhöjden är 150 meter och rotordiametern är 114 meter. Normalårsproduktionen är ca 43 miljoner kWh.',

        image:
          'https://live.staticflickr.com/65535/51816489951_d51779b6e1_w.jpg',
        alt: 'Bild Rammeldalsberget',
      },
      {
        name: 'Rödstahöjden',
        kommun: 'Sollefteå kommun',
        text: 'Vindkraftspark Rödstahöjden ligger i Sollefteå kommun, ca 8 km sydsydost om Sollefteå. 2015 driftsattes sex vindkraftverk med total installerad effekt är 19,8 MW. Vindkraftverkens totalhöjd är 150 meter och rotordiametern är 112 meter. Normalårsproduktionen är ca 57 miljoner kWh.',
        image:
          'https://live.staticflickr.com/65535/51816830774_0fea11d28e_w.jpg',
        alt: 'Bild Rödstahöjden',
      },
      {
        name: 'Stormon',
        kommun: 'Härnösands kommun',
        text: 'Vindkraftspark Stormon ligger i Härnösands kommun, ca 8 km väster om Utansjö. 2016 driftsattes fem vindkraftverk och total installerad effekt är 16 MW. Vindkraftverkens totalhöjd är 150 meter och rotordiametern är 113 meter. Normalårsproduktionen är ca 54 miljoner kWh.',
        image:
          'https://live.staticflickr.com/65535/51816822104_44b7d519cb_w.jpg',
        alt: 'Bild Stormon',
      },
    ];

    return (
      <>
        {cardData.map((data, index) => {
          return (
            <>
              <div
                className='lg:col-span-1 col-span-3 pb-12 relative mt-10 lg:mt-0'
                key={index}
              >
                <div className='max-w-sm h-48 bg-transparent mx-auto z-0 relative'>
                  <img src={data.image} alt={data.alt} className='rounded-lg' />
                </div>
                <div className='rounded-lg w-72 h-auto py-4 bg-white mx-auto -m-12 z-40 relative shadow-xl'>
                  <h4 className='text-primary'>{data.name}</h4>
                  <h5 className='text-gray-400 font-light text-base'>
                    {data.kommun}
                  </h5>
                  <Popup
                    trigger={
                      <button className='mt-2'>
                        <BsFillInfoCircleFill className='text-primary mx-auto mt-2 text-3xl' />
                      </button>
                    }
                    position='center center'
                  >
                    <div className='p-2'>{data.text}</div>
                  </Popup>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  return (
    <section className='relative md:py-36 py-28 bg-woodgreen' id='features'>
      <div className='relative max-w-xl p-6 mx-auto lg:px-8 lg:max-w-7xl text-center'>
        <h1 className='text-white'>Driftsatta vindkraftparker</h1>
        <p className='mt-2 text-darkgray'>
          Kabeko Kraft har tidigare projekterat och tagit fram nödvändiga
          tillstånd för nedan vindkraftsparker, som idag är i drift.
        </p>
        <div className='grid grid-cols-3 md:gap-10 gap-10 lg:mt-12 mt-8'>
          <VindKraftPark />
        </div>
        {/* <div className='lg:mt-16 mt-10'>
          <p className='text-darkgray'>
            För att se projekt som Kabeko Kraft tidigare har utvecklat.
          </p>
          <div className='lg:mt-4 mt-3'>
            <button className='font-montserrat rounded-full bg-secondary lg:py-3 lg:px-6 px-4 py-2 hover:bg-secondarylight uppercase font-semibold md:text-lg text-base text-white'>
              <a
                href='https://hkwind-3e5e7.web.app/'
                target='_blank'
                rel='noreferrer'
              >
                Klicka här
              </a>
            </button>
          </div>
        </div> */}
      </div>
    </section>
  );
}
