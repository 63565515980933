import React from 'react';
import HeroSection from '../views/HeroSection';
import Footer from './Footer';
import NavBar from './NavBar';

const HeroView = (props) => {
  return (
    <>
      <NavBar />
      <HeroSection />
      <Footer />
    </>
  );
};

export default HeroView;
