import Illu1 from '../assets/img/Norr.png';

export default function Norr() {
  return (
    <section className='relative md:py-36 py-28 bg-gray-50' id='norr'>
      <div className='relative max-w-xl p-6 mx-auto lg:px-8 lg:max-w-7xl'>
        <div className='grid grid-cols-4 lg:gap-20 gap-20 items-center'>
          <div className='lg:col-span-3 col-span-4'>
            <h1 className='text-primary'>Varför vindkraft i norra Sverige</h1>
            <p className='mt-2 text-whitegray'>
              Det svenska elkraftbehovet har historiskt varit störst i södra
              Sverige. Många av de planerade elintensiva industrierna som
              Hybrit, H2 Green Steel och Northvolt ligger i norra Sverige.
              Kabeko Kraft tror därför att mellersta Norrland är ett bra läge
              för att planera för ny vindkraft, eftersom elkraftproduktionen
              både kommer kunna transporteras söderut och längre norrut.
            </p>
          </div>
          <div className='lg:col-span-1 col-span-4'>
            <img
              src={Illu1}
              alt='Mellersta norrland'
              className='lg:w-44 w-40 mx-auto text-center'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
