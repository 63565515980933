import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import HeroSection from './components/Layout';
import NotFoundPage from './views/404';

function App() {
  return (
    <Router>
      <ScrollToTop
        smooth
        viewBox='0 0 24 24'
        svgPath='M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0 2c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10zm-1-10v4h2v-4h3l-4-4-4 4h3z" fill="#16365d'
        width='40'
        color='gray'
      />
      <div className='bg-gray-300'>
        <div className='max-w-screen-2xl mx-auto'>
          <Switch>
            <Route path='/' exact component={HeroSection} />
            <Route path='*' component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
