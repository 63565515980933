import Feat from '../sections/Features';
import Hero from '../sections/Hero';
import Kabeko from '../sections/Kabeko';
import Norr from '../sections/Norr';
import Vindkraft from '../sections/Vindkraft';

export default function HeroSection() {
  return (
    <>
      <Hero />
      <Kabeko />
      <Vindkraft />
      <Norr />
      <Feat />
    </>
  );
}
