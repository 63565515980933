import Illu1 from '../assets/img/kabeko_illu_5.png';

export default function Vindkraft() {
  return (
    <section className='relative md:py-36 py-28 bg-primary' id='vindkraft'>
      <div className='relative max-w-xl p-6 mx-auto lg:px-8 lg:max-w-7xl'>
        <div className='grid grid-cols-4 gap-20 md:gap-16 items-center'>
          <div className='lg:col-span-1 col-span-4 hidden lg:block'>
            <img
              src={Illu1}
              alt='Illustration 1'
              className='lg:w-24 mx-auto text-center'
            />
          </div>
          <div className='col-span-4 lg:col-span-3'>
            <h1 className='text-white lg:text-right'>Svensk elkraft</h1>
            <p className='mt-2 text-darkgray lg:text-right'>
              Sveriges årliga elkraftbehov är idag ca 140 TWh.
              Elkraftproduktionen kommer i huvudsak från vatten-, kärn- och
              vindkraft. Sverige befinner sig dock i början av en historisk
              industriell förändring som är driven av digitalisering,
              elektrifiering och en grön nyindustralisering. Prognoser visar att
              det årliga elkraftbehovet kommer att öka med ca 100 TWh inom bara
              15 års tid. För att möta detta behov kommer det krävas ny
              elkraftproduktion.
            </p>

            <p className='mt-3 text-darkgray lg:text-right'>
              Från projektstart tar en ny vindkraftpark mellan fem och tio års
              tid innan den producerar elkraft. Motsvarande tid för ny
              vattenkraft eller ny kärnkraft bedöms vara ännu längre. Kabeko
              tror därför att svensk vindkraft kommer att få en mycket viktigt
              roll under de kommande årtiondena.
            </p>
          </div>
          <div className='lg:col-span-1 col-span-4 lg:hidden sm:mt-10'>
            <img
              src={Illu1}
              alt='Illustration 1'
              className='w-24  mx-auto text-center'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
