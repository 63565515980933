import React, { useEffect, useRef, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-scroll';
import KabekoLogo from '../assets/img/Kabeko_Logo.png';
import ContactModalFunctionality from '../components/ContactModalFunctionality';
import ContactModal from '../views/ContactModal';
import NavLinks from './NavLinks';

const NavBar = () => {
  const [hamburgerToggled, setHamburgerToggled] = useState(false);
  const [navbarVersion, setNavbarVersion] = useState('init-navbar');
  const [isScrolled, setIsScrolled] = useState(false);

  const ref = useRef();
  const handleClick = () => ref.current?.open();

  const ContactBtn = () => {
    return (
      <>
        <ContactModalFunctionality ref={ref}>
          <ContactModal />
        </ContactModalFunctionality>

        <button
          className='
             text-white bg-primary
          } md:px-6 md:py-3 px-4 py-2 tracking-wide font-semibold rounded-full md:text-base text-sm hover:bg-primarylight 
          transition-all mt-4 md:mt-5 lg:mt-0 whitespace-nowrap'
          onClick={handleClick}
        >
          Kontakta oss
        </button>
      </>
    );
  };

  useEffect(() => {
    const updateNavbar = () => {
      if (
        //Scrolled navbar
        document.documentElement.scrollTop > 150 ||
        document.body.scrollTop > 150
      ) {
        setNavbarVersion('scrolled-navbar');
        setIsScrolled(true);
      } else if (
        //Initial navbar
        document.documentElement.scrollTop < 151 ||
        document.body.scrollTop < 151
      ) {
        setNavbarVersion('init-navbar');
        setIsScrolled(false);
      }
    };

    updateNavbar();

    window.addEventListener('scroll', updateNavbar);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbar);
    };
  }, []);

  return (
    <nav
      className={`${navbarVersion} left-1/2 transform -translate-x-1/2 fixed z-50 flex flex-wrap 
      justify-between w-full transition duration-500 ease-in-out max-w-screen-2xl mx-auto`}
    >
      <div className='px-6 sm:px-10 xl:px-0 flex justify-between flex-nowrap w-full items-center py-3 max-w-6xl mx-auto'>
        {/* Brand */}
        <Link
          className='cursor-pointer'
          to='hero'
          spy={true}
          smooth={true}
          duration={500}
        >
          <img
            src={KabekoLogo}
            alt='Kabeko Logo'
            className={`${
              isScrolled ? 'md:w-24 w-16' : 'lg:w-32 md:w-20 w-20'
            } transition-all`}
          />
        </Link>
        {/* Hamburger */}
        <div
          onClick={() => setHamburgerToggled(!hamburgerToggled)}
          className='cursor-pointer lg:hidden'
        >
          <GiHamburgerMenu className='text-blue_main text-3xl' />
        </div>
        {/* Desktop size */}
        <div className='hidden lg:flex items-center lg:text-lg text-base'>
          <NavLinks isScrolled={isScrolled} />
          <ContactBtn />
        </div>
      </div>
      {/* Mobile menu (hamburger toggle) */}
      {hamburgerToggled ? (
        <div className='h-screen mx-auto w-full bg-white py-10 text-xl font-semibold text-center px-10'>
          <NavLinks
            setHamburgerToggled={setHamburgerToggled}
            hamburgerToggled={hamburgerToggled}
          />

          <ContactBtn />
        </div>
      ) : null}
    </nav>
  );
};

export default NavBar;
